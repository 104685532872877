import React, { useEffect, useState } from "react";
import {
  addItemToMoodBoard,
  addMoodBoardItemValue,
  createMoodBoard,
  getMoodBoardsSharedWithMe,
  updateMoodBoard,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { AgentDataAPI } from "../../../redux/agentSlice";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import leftArrow from "../../../assets/svgs/leftArrow.svg";
import rightArrowCircle from "../../../assets/svgs/rightArrowCircle.svg"; // Import your SVG file
import leftArrowCircle from "../../../assets/svgs/leftArrowCircle.svg"; // Import your SVG file
import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import columns2Icon from "../../../assets/svgs/2columns.svg"; // Import your SVG file
import columns3Icon from "../../../assets/svgs/3columns.svg"; // Import your SVG file
import elementLibraryIcon from "../../../assets/svgs/elementaryLibrary.svg"; // Import your SVG file
import photoIcon from "../../../assets/svgs/photo.svg"; // Import your SVG file
import columns4Icon from "../../../assets/svgs/4columns.svg"; // Import your SVG file
import textIcon from "../../../assets/svgs/text.svg"; // Import your SVG file

const AddToMoodBoardButton = ({
  agentData,
  artistImage,
  artistID,
  artistImageLow,
}) => {
  const [levelmoodboardPopup, setLevelmoodboardPopup] = useState(0);
  const [moodboardPopup, setMoodboardPopup] = useState(false);
  const [newmoodboardName, setNewMoodBoardName] = useState("");
  const [selectedMoodboard, setSelectedMoodboard] = useState(null);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [sharedMoodBoards, setSharedMoodBoards] = useState([]);
  const [reverseMoodboards, setReverseMoodboards] = useState([]);
  const [orderMode, setOrderMode] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const rMoodboards = [...agentData.moodBoards].reverse();
    setReverseMoodboards(rMoodboards);
  }, [agentData])
  

  const handleNewMoodboard = (event) => {
    setNewMoodBoardName(event.target.value);
  };
  const handleCreateMoodboard = async () => {
    // Simulate API call to create a new moodboard

    try {
      await createMoodBoard({
        userId: agentData._id,
        title: newmoodboardName,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setNewMoodBoardName("");

      setLevelmoodboardPopup(0);
    } catch (error) {
      console.error("Failed to create moodBoard", error);
    }

    setNewMoodBoardName("");
    setLevelmoodboardPopup(0);
  };
  useEffect(() => {
    if (
      selectedMoodboard &&
      selectedMoodboard._id &&
      agentData &&
      agentData.moodBoards &&
      agentData.moodBoards.length > 0
    ) {
      let matchingMoodBoard = agentData.moodBoards.find(
        (moodBoard) => moodBoard._id === selectedMoodboard._id
      );

      // If no matching mood board is found in agentData, search in sharedMoodBoards
      if (
        !matchingMoodBoard &&
        sharedMoodBoards &&
        sharedMoodBoards.length > 0
      ) {
        matchingMoodBoard = sharedMoodBoards.find(
          (moodBoard) => moodBoard._id === selectedMoodboard._id
        );
      }
      // Check if a matching mood board was found
      if (matchingMoodBoard) {
        setSelectedMoodboard(matchingMoodBoard); // Update the selectedMoodboard with the found mood board
      }
    }
  }, [agentData, sharedMoodBoards]);

  const handleSelectedMoodboard = (moodboard) => {
    setSelectedMoodboard(moodboard);
    setLevelmoodboardPopup(2);
  };
  const handleSelectedElement = async (element) => {
    const userId = selectedMoodboard?.sharedByUserId || agentData._id;
    try {
      const response = await addItemToMoodBoard({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        itemType: element,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setLevelmoodboardPopup(2);
    } catch (error) {
      console.error("Error adding new element", error);
    }
    setShowSecondDropdown(false);
  };

  const fetchSharedIdeaBoards = async () => {
    try {
      const response = await getMoodBoardsSharedWithMe({
        userId: agentData._id,
      });
      setSharedMoodBoards(response.data.moodBoards);
    } catch (error) {
      console.error("Error fetching shared idea boards", error);
    }
  };
  useEffect(() => {
    if (agentData && agentData._id) {
      fetchSharedIdeaBoards();
    }
  }, [agentData]);

  const handleDeleteItem = async(event,item,j)=>{
    event.stopPropagation();
    event.preventDefault(); 
    const userId = selectedMoodboard?.sharedByUserId || agentData._id;
    const newItemValue = [...item.itemValue].filter((_,i)=> i !== j);
    const newMoodboards = [...selectedMoodboard.moodBoardItems];
    const indexOf = newMoodboards.findIndex((e)=>e._id === item._id);
    newMoodboards[indexOf] = {...newMoodboards[indexOf],itemValue:newItemValue};
    setSelectedMoodboard({...selectedMoodboard,moodBoardItems:newMoodboards});
    try {
      await updateMoodBoard({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        moodBoardItems: newMoodboards,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Changed Successfully"));
      setOrderMode(false);
    } catch (error) {
      console.error("Failed to update mood board", error);
    }
  }

  const handleOrder = ()=>{
    setOrderMode(true);
  }

  const handleOrderClose = ()=>{
    setOrderMode(false);
    const originalMoodboard = reverseMoodboards.find((e)=>e._id === selectedMoodboard._id);
    setSelectedMoodboard(originalMoodboard);
  }

  const handleSaveMoodboard = async()=>{    
    const userId = selectedMoodboard?.sharedByUserId || agentData._id;
    try {
      await updateMoodBoard({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        moodBoardItems: selectedMoodboard.moodBoardItems,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Changed Successfully"));
      setOrderMode(false);
    } catch (error) {
      console.error("Failed to update mood board", error);
    }
  }
  
  const handleMoveOrder = (position,event,item,j,arrayLength)=>{
    event.stopPropagation();
    event.preventDefault(); 
    const target = item.itemValue[j].index + position;
    const newItemValue = [...item.itemValue];

    if(target >=0 && target < arrayLength){
      if(Number.isInteger(newItemValue[j].index)){
        console.log("s");
        const s = newItemValue.findIndex((element)=>element.index === target);
        newItemValue[j] = { ...newItemValue[j] ,index : target };;
        if(s !== -1){
          newItemValue[s] = { ...newItemValue[s] ,index : newItemValue[s].index - position };
        }
      }
      const newMoodboards = [...selectedMoodboard.moodBoardItems];
      const indexOf = newMoodboards.findIndex((e)=>e._id === item._id);
      newMoodboards[indexOf] = {...newMoodboards[indexOf],itemValue:newItemValue};
      setSelectedMoodboard({...selectedMoodboard,moodBoardItems:newMoodboards});
      return;
    }

    [newItemValue[j], newItemValue[j+position]] = [
      newItemValue[j+position],
      newItemValue[j],
    ];
    const newMoodboards = [...selectedMoodboard.moodBoardItems];
    const indexOf = newMoodboards.findIndex((e)=>e._id === item._id);
    newMoodboards[indexOf] = {...newMoodboards[indexOf],itemValue:newItemValue};
    setSelectedMoodboard({...selectedMoodboard,moodBoardItems:newMoodboards});
  }

  const addValueToMoodItem = async (itemId, prevValue,index = 0) => {
    const newSingleValue = {
      artistID,
      artistImage,
      artistImageLow,
      index
    };
    let newValue;
    if (!prevValue) {
      newValue = [newSingleValue];
    } else {
      newValue = [...prevValue, newSingleValue];
    }
    try {
      const userId = selectedMoodboard?.sharedByUserId || agentData._id;

      const response = await addMoodBoardItemValue({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        itemId: itemId,

        itemValue: newValue,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Added"));
    } catch (error) {
      console.error("Error updating element", error);
    }
  };
  const handleOpenSecondDropdown = () => {
    setShowSecondDropdown(!showSecondDropdown);
  };
  const handleCloseModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(false);
  };
  const handleToggleModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(!moodboardPopup);
  };
  if (!agentData || !agentData._id) {
    return null;
  }

  const renderFiller = (item,isDisabled,index,gridLength)=>{
    const items = [];
    for(let i = 0;i < gridLength;i++){
      items.push((
        <div key={index+i} className={`CustomDropdownItem ${isDisabled ? "disabledItem" : ""}`} onClick={() => addValueToMoodItem(item._id, item.itemValue,i)}
          style={{borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
          pointerEvents: isDisabled ? "none" : "auto", // Prevents interaction if disabled
          opacity: isDisabled ? 0.5 : 1, // Lower opacity if disabled
          }}>
          <div style={{display:"flex",height:"100%",border:"dashed black",padding:"5px 0px 0px 5px",justifyContent:"center",alignItems:"center"}}>
            <p>Add image</p>
          </div>
        </div>
      ));
    }
    if(item.itemValue && item.itemValue?.length > 0){
      for(let j = 0;j < item.itemValue.length;j++){
        if( Number.isInteger(item.itemValue[j].index)){
          items[item.itemValue[j].index] = (
            <div key={item._id+(j+100)} className="CustomDropdownItem disabledItem"
              style={{position:"relative", borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
              pointerEvents: orderMode ? "": "none", // Prevents interaction if disabled
              opacity: 0.5, // Lower opacity if disabled
              }}>
              {
                orderMode &&  
                <div className="image-order-arrows">
                  <img onClick={(e)=>handleMoveOrder(-1,e,item,j,items.length)} src={leftArrowCircle} alt="Move left" className=" orderArrow" 
                    style={{backgroundColor:"black",opacity:"0.5",pointerEvents:"all"}}/>
                  <img onClick={(e)=>handleMoveOrder(1,e,item,j,items.length)} src={rightArrowCircle} alt="Move right" className=" orderArrow" 
                    style={{backgroundColor:"black",opacity:"0.5"}}/>
                </div>
              }
              <div className="accessButtonsContainer" style={{ top: "20px",right: "5px",pointerEvents:"all"}}>
                <img src={crossSvg} alt="Delete" className="accessButtons accessButtonMain" onClick={(e)=>handleDeleteItem(e,item,j)}/>
              </div>
              <div style={{padding:"5px 0px 0px 5px"}}>
                <img src={item.itemValue[j].artistImage}/>
              </div>
            </div>
          );
        }else{
          items[j]=(
            <div key={item._id+(j+100)} className="CustomDropdownItem disabledItem"
              style={{position:"relative", borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
              pointerEvents:orderMode ? "": "none", // Prevents interaction if disabled
              opacity: 0.5, // Lower opacity if disabled
              }}>
              {
                orderMode &&  
                <div className="image-order-arrows">
                  <img onClick={(e)=>handleMoveOrder(-1,e,item,j,items.length)} src={leftArrowCircle} alt="Move left" className=" orderArrow" style={{backgroundColor:"black",opacity:"0.5"}}/>
                  <img onClick={(e)=>handleMoveOrder(1,e,item,j,items.length)} src={rightArrowCircle} alt="Move right" className=" orderArrow" style={{backgroundColor:"black",opacity:"0.5"}}/>
                </div>
              }
              <div className="accessButtonsContainer" style={{ top: "20px",right: "5px",pointerEvents:"all"}}>
                <img src={crossSvg} alt="Delete" className="accessButtons accessButtonMain" onClick={(e)=>handleDeleteItem(e,item,j)}/>
              </div>
              <div style={{padding:"5px 0px 0px 5px"}}>
                <img src={item.itemValue[j].artistImage}/>
              </div>
            </div>
          )
        }
      }
    }
    return items;
  }

  const renderIdeaBoard = (item,index,isDisabled)=>{
    switch (item.itemType){
      case "text":
        return(
          <div key={index} className={`CustomDropdownItem ${isDisabled ? "disabledItem" : ""}`} onClick={() => addValueToMoodItem(item._id, item.itemValue)}
            style={{ borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
            pointerEvents: isDisabled ? "none" : "auto", // Prevents interaction if disabled
            opacity: isDisabled ? 0.5 : 1, // Lower opacity if disabled
            }}>
              {
                item.itemValue && item.itemValue !== "" ? (
                <div className="BioDetailDescriptionPortfolio" dangerouslySetInnerHTML={{ __html: item.itemValue}}>
                </div>)
                :
                (<p>{item.itemName}</p>)
              }
          </div>
        );
        case "upload":
        return(
          <div key={index} className={`CustomDropdownItem ${isDisabled ? "disabledItem" : ""}`} onClick={() => addValueToMoodItem(item._id, item.itemValue)}
            style={{ borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
            pointerEvents: isDisabled ? "none" : "auto", // Prevents interaction if disabled
            opacity: isDisabled ? 0.5 : 1, // Lower opacity if disabled
            }}>
            {
              (item.itemValue && item.itemValue.length !== 0 )? (<img src={item.itemValue}/>) : (<p>{item.itemName}</p>)
            }
          </div>
        );
        case "grid_1":
          return(
            <div key={index} className={`CustomDropdownItem ${isDisabled ? "disabledItem" : ""}`} onClick={() => addValueToMoodItem(item._id, item.itemValue)}
              style={{position:"relative", borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
              pointerEvents: isDisabled ? "none" : "auto", // Prevents interaction if disabled
              opacity: isDisabled ? 0.5 : 1, // Lower opacity if disabled
              }}>
              <div className="accessButtonsContainer" style={{ top: "20px",right: "5px",pointerEvents:"all"}}>
                <img src={crossSvg} alt="Delete" className="accessButtons accessButtonMain" onClick={(e)=>handleDeleteItem(e,item,0)}/>
              </div>
              <div style={{display:"flex",border:(item.itemValue && item.itemValue.length !== 0)?"":"dashed black",padding:"5px 0px 0px 5px",justifyContent:"center",alignItems:"center"}}>
                {
                  (item.itemValue && item.itemValue.length !== 0) ? item.itemValue.map((itemImg)=><img src={itemImg.artistImage}/>) : (<p>Add image</p>)
                }
              </div>
            </div>
          );
        case "grid_2":
          return <div style={{display:"grid",gridTemplateColumns:"49% 50%",columnGap:"10px"}}>
            {renderFiller(item,isDisabled,index,2)}
          </div>
        case "grid_3":
          return <div style={{display:"grid",gridTemplateColumns:"32.3% 33% 32.3%",columnGap:"10px"}}>
            {renderFiller(item,isDisabled,index,3)}
          </div>
        case "grid_4":
          return <div style={{display:"grid",gridTemplateColumns:"24% 24% 24% 24%",columnGap:"10px"}}>
            {renderFiller(item,isDisabled,index,4)}
          </div>
      default:
        return(
          <div key={index} className={`CustomDropdownItem ${isDisabled ? "disabledItem" : ""}`} onClick={() => addValueToMoodItem(item._id, item.itemValue)}
            style={{ borderBottom: "solid 1px #B0B0B0", padding: "10px 0",textAlign: "left",backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
            pointerEvents: isDisabled ? "none" : "auto", // Prevents interaction if disabled
            opacity: isDisabled ? 0.5 : 1, // Lower opacity if disabled
            }}>
              {
                item.itemValue && item.itemValue !== "" ? (
                <div className="BioDetailDescriptionPortfolio" dangerouslySetInnerHTML={{ __html: item.itemValue}}>
                </div>)
                :
                (<p>{item.itemName}</p>)
              }
          </div>
        );
    }
  }

  return (
    <div style={{ position: "absolute", top: "30px", right: "7%" }}>
      <img
        src={
          !moodboardPopup
            ? "/assets/images/menuWithAddOption.svg"
            : "/assets/images/x.svg"
        }
        style={{ backgroundColor: "white", padding:"4px",width:"40px",borderRadius:"5px"}}
        onClick={handleToggleModal}
      />
      {moodboardPopup && (
        <div
          className="CustomDropdownMenu"
          style={{
            width: levelmoodboardPopup !== 2 ? "400px" : "800px", 
            height: levelmoodboardPopup !== 2 ? "300px":" 600px",
            minWidth: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px 20px",
            overflow: "scroll",
          }}
        >
          {levelmoodboardPopup === 0 && (
            <>
              <div style={{ display: "flex",justifyContent:"flex-end" }}>
                <button
                  className="talentbutton"
                  style={{ marginRight: "10px", fontSize: "10px" }}
                  onClick={() => setLevelmoodboardPopup(1)}
                >
                  New MyIdea Board
                </button>
              </div>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "10px" }}
                >
                  MyIdea Boards
                </div>
                {reverseMoodboards.map((moodboard) => (
                  <div
                    key={moodboard._id}
                    className="CustomDropdownItem"
                    style={{
                      borderBottom: "solid 1px #B0B0B0",
                      padding: "10px 0",
                      textAlign: "left",
                    }}
                    onClick={() => handleSelectedMoodboard(moodboard)}
                  >
                    {moodboard.title}
                  </div>
                ))}
              </div>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", margin: "10px 0 10px 0" }}
                >
                  MyShared Idea Boards
                </div>
                {sharedMoodBoards.map((moodboard) => (
                  <div
                    key={moodboard._id}
                    className="CustomDropdownItem"
                    style={{
                      borderBottom: "solid 1px #B0B0B0",
                      padding: "10px 0",
                      textAlign: "left",
                    }}
                    onClick={() => handleSelectedMoodboard(moodboard)}
                  >
                    {moodboard.title}
                  </div>
                ))}
              </div>
            </>
          )}
          {levelmoodboardPopup === 1 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  <button
                    className="mr-2"
                    onClick={() => setLevelmoodboardPopup(0)}
                  >
                    <img src={leftArrow} alt="back" />
                  </button>
                  Create New MyIdea Board
                </div>
                <input
                  type="text"
                  className="text-field text_h w-input"
                  name="moodboardName"
                  placeholder="Enter MyIdea board title"
                  value={newmoodboardName}
                  required
                  onChange={handleNewMoodboard}
                  style={{ width: "200px" }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="talentbutton"
                  style={{ fontSize: "10px" }}
                  onClick={handleCreateMoodboard}
                >
                  CREATE
                </button>
              </div>
            </>
          )}
          {levelmoodboardPopup === 2 && selectedMoodboard && (
            <>
              <div>
                <div style={{ color: "rgb(250, 142, 55)"}}>
                  <button className="mr-2" onClick={() => setLevelmoodboardPopup(0)}>
                    <img src={leftArrow} alt="back" />
                  </button>
                  {/* {selectedMoodboard.title} */}
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "auto 1fr",marginBottom:"20px"}}>
                  <h2 className="hMyshannon" style={{ color: "rgb(250, 142, 55)", marginBottom: "0px",fontSize:"1vw" }}>
                    Creating MyIdea Board
                  </h2>
                  <div className="separatorSubCat"></div>
                </div>
                <h2 className="newsh2 h" style={{fontSize:"1.4vw",marginBottom:"5px"}}>{selectedMoodboard.title}</h2>
                <div style={{ display: "flex",marginBottom:"20px"}}>
                  {
                    orderMode ? (<>
                      <button onClick={handleSaveMoodboard} className="talentbutton" style={{ marginRight: "10px", fontSize: "10px"}}>
                        SAVE
                      </button>
                      <button onClick={handleOrderClose} className="talentbutton" style={{ marginRight: "10px", fontSize: "10px"}}>
                        CANCEL
                      </button>
                    </>)
                    :(<>
                      <button onClick={handleOrder} className="talentbutton" style={{ marginRight: "10px", fontSize: "10px"}}>
                        ORDER
                      </button>
                    </>)
                  }
                </div>
                {selectedMoodboard.moodBoardItems?.map((item, index) => {
                  // Determine if the item should be disabled
                  const isDisabled =
                    item.itemType === "text" || item.itemType === "upload" ||(item.itemType === "grid_1" &&item.itemValue?.length >= 1) ||
                    (item.itemType === "grid_2" && item.itemValue?.length >= 2) ||(item.itemType === "grid_3" && item.itemValue?.length >= 3) ||
                    (item.itemType === "grid_4" && item.itemValue?.length >= 4);
                  return renderIdeaBoard(item,index,isDisabled);
                })}
                <div>
                  <div className="elementaryContainerMini">
                    <div className="elementaryBoxMini">
                      <img className="elementaryBoxIconMini elementaryLibraryIconMini" src={elementLibraryIcon}/>
                      <div>
                        <p className="elementaryBoxTextMini"> ELEMENT LIBRARY</p>
                      </div>
                    </div>
                    <div className="elemtaryElementsContainerMini">
                      <div onClick={() => handleSelectedElement("text")} className="elemtaryElementsBoxMini">
                        <img className="elementaryBoxIconMini" src={textIcon}/>
                        <div>
                          <p className="elementaryBoxTextMini">TEXT</p>
                        </div>
                      </div>
                      <div onClick={() => handleSelectedElement("grid_1")} className="elemtaryElementsBoxMini">
                        <img className="elementaryBoxIconMini" src={photoIcon}/>
                        <div>
                          <p className="elementaryBoxTextMini">FULL WIDTH IMAGE</p> 
                        </div>
                      </div>
                      <div onClick={() => handleSelectedElement("grid_2")} className="elemtaryElementsBoxMini">
                        <img className="elementaryBoxIconMini columns2IconMini" src={columns2Icon}/>
                        <div>
                          <p className="elementaryBoxTextMini">2 IMAGE COLUMN GRID</p> 
                        </div>
                      </div>
                      <div onClick={() => handleSelectedElement("grid_3")} className="elemtaryElementsBoxMini">
                        <img className="elementaryBoxIconMini columns3IconMini" src={columns3Icon}/>
                        <div>
                          <p className="elementaryBoxTextMini">3 IMAGE COLUMN GRID</p>                     
                        </div>
                      </div>
                      <div onClick={() => handleSelectedElement("grid_4")} className="elemtaryElementsBoxMini">
                        <img className="elementaryBoxIconMini columns3IconMini" src={columns4Icon}/>
                        <div>
                          <p className="elementaryBoxTextMini">4 IMAGE COLUMN GRID</p>                     
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {levelmoodboardPopup === 3 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  <button
                    className="mr-2"
                    onClick={() => setLevelmoodboardPopup(2)}
                  >
                    <img src={leftArrow} alt="back" />
                  </button>
                  Create New Moodboard Item
                </div>
                <div
                  className="CustomDropdownItem"
                  style={{
                    borderBottom: "solid 1px #B0B0B0",
                    padding: "10px 0",
                    textAlign: "left",
                  }}
                  onClick={() => handleOpenSecondDropdown()}
                >
                  GRID
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {showSecondDropdown && (
        <div
          className="CustomDropdownMenu"
          style={{ position: "absolute", right: "20px", top: "110px" }}
        >
          <div>Columns</div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_1")}
          >
            1
          </div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_2")}
          >
            2
          </div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_3")}
          >
            3
          </div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_4")}
          >
            4
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToMoodBoardButton;
