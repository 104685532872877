import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RecentlyArtistImageSliceData } from "../../redux/recentlyArtistImageDataSlice";
import loading from "../../assets/loading.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function UpdatedArtists(props) {
  const dispatch = useDispatch();
  const { recentlyArtistImageDataSlice } = useSelector((state) => state);
  const [filterCond, setFilterCond] = useState(true);
  const [tempArtist, setTempArtist] = useState([]);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const history = useHistory();

  const filterChange = (filter) => {
    if (filter === "A-Z") {
      let temp = [];
      setFilterCond(false);
      let tempImage = [...recentlyArtistImageDataSlice.artistImages];
      temp = tempImage.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
      setFilterHighlighted(2);
      setTempArtist(temp);
    } else {
      setFilterHighlighted(1);
      setFilterCond(true);
    }
  };

  useEffect(() => {
    if (recentlyArtistImageDataSlice.artistImages.length === 0) {
      dispatch(RecentlyArtistImageSliceData());
    }
  }, [recentlyArtistImageDataSlice.artistImages.length, dispatch]);

  const updateTempArtist = (e) => {
    if (recentlyArtistImageDataSlice.artistImages.length) {
      const searchvalue = e.toLowerCase();
      let temp = recentlyArtistImageDataSlice.artistImages.filter(function (
        element
      ) {
        let checker = false;
        if (
          element.artistId.firstname.toLowerCase().includes(searchvalue) ||
          element.artistId.lastname.toLowerCase().includes(searchvalue)
        ) {
          checker = true;
        }
        return checker;
      });
      setFilterHighlighted(null);
      setTempArtist(temp);
    }
  };

  useEffect(() => {
    updateTempArtist(props.searchArtist);
  }, [recentlyArtistImageDataSlice, props.searchArtist]);

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <button
            class={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </button>
          <button
            class={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </button>
        </div>
      </div>
      <div className="_2cols" style={{ clear: "both" }}>
        {props.children}
        <div>
          <div>
            {JSON.parse(localStorage.getItem("routePaths")) ? (
              <div
                className={"d-flex"}
                style={{ marginBottom: "10px", width: "98.4%" }}
              >
                {JSON.parse(localStorage.getItem("routePaths")).map(
                  (obj, ind) => (
                    <p
                      key={ind}
                      className={
                        JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? "breadCrumbs"
                          : "breadCrumbsActive"
                      }
                      onClick={
                        JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? () => {}
                          : () => history.push(obj.link)
                      }
                      style={ind === 0 ? {} : { marginLeft: "5px" }}
                    >
                      {obj.val}{" "}
                      {JSON.parse(localStorage.getItem("routePaths")).length ===
                      ind + 1
                        ? null
                        : ">"}
                    </p>
                  )
                )}
              </div>
            ) : null}
          </div>
          <h2 className="newsh2 h">UPDATED PORTFOLIOS</h2>
          <div
            id="w-node-a284be2a-4b91-3177-03eb-6614b24879c1-4bf2d022"
            className="_4cols-v2 heightIssue"
          >
            {recentlyArtistImageDataSlice.loading ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <img className="mb-3" alt="loading" src={loading} />
              </div>
            ) : recentlyArtistImageDataSlice.artistImages &&
              props.searchArtist === "" &&
              filterCond ? (
              recentlyArtistImageDataSlice.artistImages.map((val, ind) => {
                return (
                  <>
                    <Link
                      id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                      data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                      to={val.artistId.fullName}
                      className="artistcard"
                    >
                      <img
                        src={String(val.mainImage[0].subImage[0].path)}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      <div className="artistnamediv">
                        <div className="artistnametext-v3">
                          {val.artistId.firstname} {val.artistId.lastname}
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })
            ) : (
              tempArtist.map((val, key) => (
                <>
                  <Link
                    id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                    data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                    to={val.artistId.fullName}
                    className="artistcard "
                  >
                    <img
                      src={String(val.mainImage[0].subImage[0].path)}
                      loading="lazy"
                      alt=""
                      className="image"
                    />
                    <div className="artistnamediv">
                      <div className="artistnametext-v3">
                        {val.artistId.firstname} {val.artistId.lastname}
                      </div>
                    </div>
                  </Link>
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatedArtists;
