import React, { useEffect, useState } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { decodeToken } from "react-jwt";
import ArtistSideBar from "../../components/layout/artistSideBar";
import DivisionSideBar from "../../components/layout/divisionSideBar";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import Navbar from "../../components/layout/navbar";
import NewestArtists from "./newestArtists";
import UpdatedArtists from "./updatedArtists";
import About from "./about";
import Artists from "./artists";
import Bipoc from "./bipoc";
import GraphicNovelN from "./graphicNovelNew";
import SearchByGraphicNovel from "./searchByGraphicNovel";
import CGI from "./cgi";
import MOTION from "./motion";
import Contact from "./contact";
import Divisions from "./divisions";
import Categories from "./categories";
import IllustrationArtists from "./illustration-artists";
import Photography from "./photography";
import SearchByArtist from "./searchPages/searchByArtist";
import SearchByDivision from "./searchPages/searchByDivision";
import SearchByCategories from "./searchPages/searchByCategories";
import BlackArtist from "./blackArtist";
import AsianArtist from "./asianArtist";
import CentralAsianArtist from "./centralAsianArtist";
import LatinoArtist from "./latinoArtist";
import IndigenousArtist from "./indigenousArtist";
import {
  artistIfExist,
  getSingleAgent,
  getSingleClient,
} from "../../AxiosFunctions/Axiosfunctionality";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ArtistDataAPI } from "../../redux/artistDataSlice";
import { AgentDataAPI } from "../../redux/agentSlice";
import { keywordDataApi } from "../../redux/keywordSlice";
import MedicallN from "./medicalNew";
import SearchByMedical from "./searchByMedical";
import Mentorship from "./mentorship";
import Philanthropy from "./philanthropy";
import Properties from "./properties";
import Motions from "./motions";
import AgentUser from "./agentUser";
import ClientUser from "./clientUser";
import { ClientDataAPI } from "../../redux/clientSlice";

function Index() {
  const { pages } = useParams();
  const { search } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();
  const {
    artistImageDataSlice,
    AgentDataAPI: agentData,
    artistImageKeywordDataSlice,
  } = useSelector((state) => state);
  const [searchArtist, setSearchArtist] = useState("");
  const [tempArtist, setTempArtist] = useState([]);

  const [searchDivision, setSearchDivision] = useState("");
  const [tempDivision, setTempDivision] = useState([]);

  const [currentCategory, setCurrentCategory] = useState(
    localStorage.getItem("Category")
  );
  const [currentBipoc, setCurrentBipoc] = useState(
    localStorage.getItem("Bipoc")
  );

  const [currentGraphical, setcurrentGraphical] = useState("");
  const [currentMedical, setcurrentMedical] = useState("");

  const [artistExists, setArtistExists] = useState(false);

  const [token, setToken] = useState(localStorage.getItem("authorization"));
  const [clientDetails, setClientDetails] = useState();
  const [agentDetails, setAgentDetails] = useState();

  const divisions = [
    "illustration-artists",
    "cgi",
    "medical",
    "motion",
    "photography",
    "divisions",
    "graphicNovel",
  ];

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve the token from localStorage

      if (token) {
        // Decode the token
        const details = decodeToken(token);

        if (details && details._id) {
          try {
            // Fetch the artist details
            const response = await getSingleClient({ _id: details._id });
            if (response === "NO CLIENT FOUND") {
              setClientDetails(null);
            } else {
              setClientDetails(response);
            }
            // Fetch the artist details
            const responseAgent = await getSingleAgent({ _id: details._id });
            if (responseAgent === "NO AGENT FOUND") {
              setAgentDetails(null);
            } else {
              setAgentDetails(responseAgent);
            }
          } catch (error) {
            // Handle any errors, such as network issues or decoding problems
            setClientDetails(null);
            setAgentDetails(null);

            console.error("Error fetching artist details:", error);
          }
        }
      } else {
        setAgentDetails(null);
        setClientDetails(null);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = localStorage.getItem("authorization");
      if (storedToken !== token) {
        setToken(storedToken);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [token]);

  const updateTempArtist = (e) => {
    setSearchArtist(e.target.value);
    const searchvalue = e.target.value.toLowerCase();
    setTempArtist(
      artistImageDataSlice !== undefined
        ? artistImageDataSlice.artistImages.filter(function (element) {
            let checker = false;
            if (
              element.artistId.firstname.toLowerCase().includes(searchvalue) ||
              element.artistId.lastname.toLowerCase().includes(searchvalue)
            ) {
              checker = true;
            }
            return checker;
          })
        : []
    );
  };

  const updateTempDivision = (e) => {
    setSearchDivision(e.target.value);
    const searchvalue = e.target.value.toLowerCase();
    setTempDivision(
      artistImageKeywordDataSlice !== undefined
        ? artistImageKeywordDataSlice.artistKeywordImages.filter(function (
            element
          ) {
            return element.keyword.toLowerCase().includes(searchvalue);
          })
        : []
    );
  };
  const checkArtistExistence = async () => {
    const response = await artistIfExist({ fullName: pages });
    if (response.length > 0) {
      setArtistExists(true);
    } else if (response.length === 0) {
      setArtistExists(false);
    } else {
      history.push("/404");
    }
  };
  useEffect(() => {
    if (pages) {
      checkArtistExistence();
    }
  }, [pages]);

  useEffect(() => {
    dispatch(ArtistDataAPI());
    dispatch(keywordDataApi());
  }, [dispatch]);
  useEffect(() => {
    if (agentDetails) {
      dispatch(AgentDataAPI({ _id: agentDetails._id }));
    }
  }, [dispatch, agentDetails]);
  useEffect(() => {
    if (clientDetails) {
      dispatch(ClientDataAPI({ _id: clientDetails._id }));
    }
  }, [dispatch, clientDetails]);
  useEffect(() => {
    setSearchArtist("");
    if (pages === undefined) {
      localStorage.setItem("Category", "none");
      localStorage.setItem("Bipoc", "none");
      localStorage.removeItem("routePaths");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
      setcurrentMedical("");
    }

    if (divisions.includes(pages)) {
      if (pages === "divisions") {
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Divisions", link: "./divisions" },
        ]; //,{val:"Illustration",link:"./illustration-artists"}
        localStorage.setItem("routePaths", JSON.stringify(route));
        localStorage.setItem("Category", "none");
      } else if (pages === "illustration-artists") {
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Divisions", link: "./divisions" },
          { val: "Illustration", link: "./illustration-artists" },
        ];
        localStorage.setItem("routePaths", JSON.stringify(route));
        localStorage.setItem("Category", "Illustration");
      } else {
        localStorage.setItem(
          "Category",
          pages.charAt(0).toUpperCase() + pages.slice(1)
        );
        let letter = pages.charAt(0).toUpperCase() + pages.slice(1);

        if (letter === "GraphicNovel") {
          letter = "Graphic Novel";
        }
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Divisions", link: "./divisions" },
          { val: letter, link: "./" + pages },
        ];
        localStorage.setItem("routePaths", JSON.stringify(route));
      }

      if (search) {
        const routeGraphical = search;
        const subGraphical = search.charAt(0).toUpperCase() + search.slice(1);
        localStorage.setItem(
          "Category",
          pages.charAt(0).toUpperCase() + pages.slice(1)
        );
        let letter = pages.charAt(0).toUpperCase() + pages.slice(1);
        if (letter === "GraphicNovel") {
          letter = "Graphic Novel";
        }
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Divisions", link: "./divisions" },
          { val: letter, link: "/" + pages },
          { val: subGraphical, link: `./graphicNovel/${routeGraphical}` },
        ];
        localStorage.setItem("routePaths", JSON.stringify(route));
      }
      localStorage.removeItem("graphicNovel");
      localStorage.setItem("Bipoc", "none");
      setcurrentMedical("");
      setcurrentGraphical("");
    }

    if (pages === "categories") {
      const route = [
        { val: "SAHome", link: "./" },
        { val: "Categories", link: "./categories" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
      if (search) {
        const path = localStorage.getItem("Category");
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Categories", link: "./categories" },
          { val: path, link: "./categories/" + search },
        ];
        localStorage.setItem("routePaths", JSON.stringify(route));
      }
      localStorage.setItem("Bipoc", "none");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
      setcurrentMedical("");
    }

    if (pages === "graphicNovel") {
      localStorage.setItem("Category", "none");
      localStorage.setItem("Bipoc", "none");
      if (search) {
        setcurrentGraphical(search);
      } else {
        setcurrentGraphical("GRAPHIC NOVEL");
      }
    }
    if (pages === "medical") {
      localStorage.setItem("Category", "none");
      localStorage.setItem("Bipoc", "none");
      if (search) {
        setcurrentMedical(search);
      }
    }
    if (pages === "bipoc") {
      if (search) {
        let letter = search.charAt(0).toUpperCase() + search.slice(1);
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Bipoc", link: "./bipoc" },
          { val: letter, link: "./bipoc/" + search },
        ];
        localStorage.setItem("routePaths", JSON.stringify(route));
        localStorage.setItem("Bipoc", letter);
      } else {
        const route = [
          { val: "SAHome", link: "./" },
          { val: "Bipoc", link: "./bipoc" },
        ];
        localStorage.setItem("routePaths", JSON.stringify(route));
        localStorage.setItem("Bipoc", "BIPOC");
      }
      localStorage.removeItem("graphicNovel");
      localStorage.setItem("Category", "none");
      setcurrentGraphical("");
    }

    if (pages === "newest") {
      const route = [
        { val: "SAHome", link: "./" },
        { val: "New Illustrators", link: "./newest" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
      localStorage.setItem("Category", "none");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
    }

    if (pages === "recentlyUpdated") {
      const route = [
        { val: "SAHome", link: "./" },
        { val: "Updated Portfolios", link: "./recentlyUpdated" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
      localStorage.setItem("Category", "none");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
    }

    if (pages === "properties") {
      const route = [
        { val: "SAHome", link: "./" },
        { val: "Properties", link: "./properties" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
      localStorage.setItem("Category", "none");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
    }

    if (pages === "mentorship") {
      const route = [
        { val: "SAHome", link: "./" },
        { val: "Mentorship", link: "./mentorship" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
      localStorage.setItem("Category", "none");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
    }

    if (pages === "philanthropy") {
      const route = [
        { val: "SAHome", link: "./" },
        { val: "Philanthropy", link: "./philanthropy" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
      localStorage.setItem("Category", "none");
      localStorage.removeItem("graphicNovel");
      setcurrentGraphical("");
    }

    if (pages === "myshannon") {
      const route = [
        { val: "SAHome", link: "/" },
        { val: "MyShannon", link: "./myshannon" },
      ];
      localStorage.setItem("routePaths", JSON.stringify(route));
    }

    setCurrentCategory(localStorage.getItem("Category"));
    setCurrentBipoc(localStorage.getItem("Bipoc"));
  }, [pages, search]);
  return (
    <>
      <Header
        aciveBtn={pages}
        graphicNovel={currentGraphical}
        clientDetails={clientDetails}
        agentDetails={agentDetails}
      />
      <div
        className={"homesection" + " wf-section " + (pages ? "divisions" : "")}
      >
        <div className={"containerhome home"}>
          <Navbar
            aciveBtn={pages}
            searchBar={true}
            searchArtist={searchArtist}
            updateTempArtist={updateTempArtist}
            currentCat={currentCategory}
            currentBipoc={currentBipoc}
            currentGraphical={currentGraphical}
            currentMedical={currentMedical}
          />
          {pages ? (
            pages === "categories" ? (
              search ? (
                <SearchByCategories
                  searchArtist={searchArtist}
                  searchDivision={searchDivision}
                  updateTempDivision={updateTempDivision}
                  tempDivision={tempDivision}
                >
                  <DivisionSideBar
                    activeBtn={pages}
                    currentCat={currentCategory}
                    currentBipoc={currentBipoc}
                  />
                </SearchByCategories>
              ) : (
                <Categories
                  searchArtist={searchArtist}
                  searchDivision={searchDivision}
                  updateTempDivision={updateTempDivision}
                  tempDivision={tempDivision}
                >
                  <DivisionSideBar
                    activeBtn={pages}
                    currentCat={currentCategory}
                    currentBipoc={currentBipoc}
                  />
                </Categories>
              )
            ) : pages === "divisions" ? (
              search ? (
                <SearchByDivision>
                  <DivisionSideBar
                    activeBtn={search}
                    currentCat={currentCategory}
                    currentBipoc={currentBipoc}
                  />
                </SearchByDivision>
              ) : (
                <Divisions
                  searchArtist={searchArtist}
                  searchDivision={searchDivision}
                  updateTempDivision={updateTempDivision}
                  tempDivision={tempDivision}
                >
                  <DivisionSideBar
                    activeBtn={pages}
                    currentCat={currentCategory}
                    currentBipoc={currentBipoc}
                    currentGraphical={currentGraphical}
                  />
                </Divisions>
              )
            ) : pages === "artist" ? (
              // search?
              // <SearchByArtist>
              //     <DivisionSideBar activeBtn="detailedPage" currentCat={currentCategory} />
              // </SearchByArtist>
              // :
              <Artists agentData={agentDetails}>
                <ArtistSideBar />
              </Artists>
            ) : pages === "myshannon" ? (
              <AgentUser agentDetails={agentData?.agentData} agentID={search} />
            ) : pages === "client" ? (
              <ClientUser clientDetails={clientDetails} clientID={search} />
            ) : pages === "illustration-artists" ? (
              <IllustrationArtists searchArtist={searchArtist}>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={currentBipoc}
                  currentGraphical={currentGraphical}
                />
              </IllustrationArtists>
            ) : pages === "cgi" ? (
              <CGI searchArtist={searchArtist}>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={currentBipoc}
                  currentGraphical={currentGraphical}
                />
              </CGI>
            ) : pages === "medical" ? (
              search ? (
                <SearchByMedical searchArtist={searchArtist} medical={search}>
                  <DivisionSideBar
                    activeBtn={search}
                    currentArtist={search}
                    currentCat={currentCategory}
                    medical={search}
                    currentBipoc="none"
                  />
                </SearchByMedical>
              ) : (
                <MedicallN setMedical={setcurrentMedical}>
                  <DivisionSideBar
                    activeBtn="medical"
                    currentArtist={pages}
                    currentCat={currentCategory}
                    currentBipoc="none"
                    medical={currentMedical}
                  />
                </MedicallN>
              )
            ) : pages === "motion" ? (
              <MOTION searchArtist={searchArtist}>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={currentBipoc}
                  currentGraphical={currentGraphical}
                />
              </MOTION>
            ) : pages === "photography" ? (
              <Photography searchArtist={searchArtist}>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={currentBipoc}
                  currentGraphical={currentGraphical}
                />
              </Photography>
            ) : pages === "motions" ? (
              <Motions>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={currentBipoc}
                  currentGraphical={currentGraphical}
                />
              </Motions>
            ) : pages === "newest" ? (
              <NewestArtists searchArtist={searchArtist}>
                <ArtistSideBar activeBtn={pages} />
              </NewestArtists>
            ) : pages === "recentlyUpdated" ? (
              <UpdatedArtists searchArtist={searchArtist}>
                <ArtistSideBar activeBtn={pages} />
              </UpdatedArtists>
            ) : pages === "mentorship" ? (
              <Mentorship>
                <ArtistSideBar aciveBtn={pages} />
              </Mentorship>
            ) : pages === "philanthropy" ? (
              <Philanthropy>
                <ArtistSideBar aciveBtn={pages} />
              </Philanthropy>
            ) : pages === "about" ? (
              <About />
            ) : pages === "properties" ? (
              <Properties access={clientDetails}>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc="none"
                  currentGraphical={currentGraphical}
                />
              </Properties>
            ) : pages === "contact" ? (
              <Contact agentDetails={agentDetails}/>
            ) : pages === "bipoc" && search === "black" ? (
              <BlackArtist>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={search}
                  currentGraphical={currentGraphical}
                />
              </BlackArtist>
            ) : pages === "bipoc" && search === "asianArtist" ? (
              <AsianArtist>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={search}
                  currentGraphical={currentGraphical}
                />
              </AsianArtist>
            ) : pages === "bipoc" && search === "latinoArtist" ? (
              <LatinoArtist>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={search}
                  currentGraphical={currentGraphical}
                />
              </LatinoArtist>
            ) : pages === "bipoc" && search === "centralAsianArtist" ? (
              <CentralAsianArtist>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={search}
                  currentGraphical={currentGraphical}
                />
              </CentralAsianArtist>
            ) : pages === "bipoc" && search === "indigenousArtist" ? (
              <IndigenousArtist>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc={search}
                  currentGraphical={currentGraphical}
                />
              </IndigenousArtist>
            ) : pages === "bipoc" ? (
              <Bipoc>
                <DivisionSideBar
                  activeBtn={pages}
                  currentCat={currentCategory}
                  currentBipoc="BIPOC"
                  currentGraphical={currentGraphical}
                />
              </Bipoc>
            ) : pages === "graphicNovel" ? (
              search ? (
                <SearchByGraphicNovel
                  searchArtist={searchArtist}
                  graphicNovel={search}
                >
                  <DivisionSideBar
                    activeBtn={search}
                    currentArtist={search}
                    currentCat={currentCategory}
                    graphicNovel={search}
                    currentBipoc="none"
                  />
                </SearchByGraphicNovel>
              ) : (
                <GraphicNovelN setcurrentGraphical={setcurrentGraphical}>
                  <DivisionSideBar
                    activeBtn="graphicNovel"
                    currentArtist={pages}
                    currentCat={currentCategory}
                    currentBipoc="none"
                    graphicNovel={currentGraphical}
                  />
                </GraphicNovelN>
              )
            ) : checkArtistExistence() ? (
              <SearchByArtist
                currentGraphical={currentGraphical}
                currentBipoc={currentBipoc}
                setCurrentCategory={setCurrentCategory}
              >
                <DivisionSideBar
                  activeBtn="detailedPage"
                  currentArtist={pages}
                  currentCat={currentCategory}
                  currentBipoc={currentBipoc}
                  currentGraphical={currentGraphical}
                  graphicNovel={currentGraphical}
                  medical={currentMedical}
                />
              </SearchByArtist>
            ) : (
              <Redirect to="/404" />
            )
          ) : (
            <Artists
              agentData={agentDetails}
              tempArtist={tempArtist}
              searchArtist={searchArtist}
            >
              <ArtistSideBar activeBtn={pages} />
            </Artists>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Index;
