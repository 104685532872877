import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import {
  addItemToMoodBoard,
  addItemWithValueToMoodBoard,
  addMoodBoardItemValue,
  addShareEditEmailToMoodBoard,
  apiCallToGeneratePDF,
  deleteMoodBoardItem,
  updateMoodBoard,
  updateMoodBoardItem,
  updateShareEditEmailToMoodBoard,
  uploadFiles,
} from "../../../AxiosFunctions/Axiosfunctionality";
import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import editButton from "../../../assets/svgs/edit.svg"; // Import your SVG file
import copyButton from "../../../assets/svgs/copyIcon.svg"; // Import your SVG file
import downArrow from "../../../assets/svgs/downArrow.svg"; // Import your SVG file
import upArrow from "../../../assets/svgs/upArrow.svg"; // Import your SVG file
import rightArrowCircle from "../../../assets/svgs/rightArrowCircle.svg"; // Import your SVG file
import leftArrowCircle from "../../../assets/svgs/leftArrowCircle.svg"; // Import your SVG file
import columns2Icon from "../../../assets/svgs/2columns.svg"; // Import your SVG file
import columns3Icon from "../../../assets/svgs/3columns.svg"; // Import your SVG file
import columns4Icon from "../../../assets/svgs/4columns.svg"; // Import your SVG file
import elementLibraryIcon from "../../../assets/svgs/elementaryLibrary.svg"; // Import your SVG file
import imageUploadIcon from "../../../assets/svgs/imageUpload.svg"; // Import your SVG file
import photoIcon from "../../../assets/svgs/photo.svg"; // Import your SVG file
import textIcon from "../../../assets/svgs/text.svg"; // Import your SVG file
import MyPopup from "../../../components/myPopup/myPopup";
import { updateMessage, updateOpen } from "../../../redux/message";
import { useDispatch } from "react-redux";
import { AgentDataAPI } from "../../../redux/agentSlice";
import { useHistory } from "react-router-dom";
import loadingUpdate from "../../../assets/loading_trasnparent.gif";
import PlusIcon from "../../../assets/svgs/plusIcon.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AgentMoodBoardEdit = ({
  searchedAgentDetails,
  agentDetails,
  moodBoardId,
  haveEditAccess,
  moodBoards,
  activeTab,
  fetchSharedIdeaBoards = async () => {},
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [allElements, setAllElements] = useState([]);
  const [currentMoodBoard, setCurrentMoodBoard] = useState({});
  const [textElement, setTextElement] = useState("");
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [shareModalActive, setShareModalActive] = useState(false);
  const [addImageModal, setAddImageModal] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [selectedMoodBoardItem, setSelectedMoodBoardItem] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [shareSuccess, setShareSuccess] = useState("");
  const [shareFailed, setShareFailed] = useState("");
  const [dropDownAdd, setDropDownAdd] = useState(false);
  const [dropDown2Add, setDropDown2Add] = useState(false);
  const [orderElements, setOrderElements] = useState(false);
  const [removingEmailIndex, setRemovingEmailIndex] = useState(null);
  const [addingAgent, setAddingAgent] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUrl = window.location.href;
  // Load the current moodBoard details
  const isMounted = useRef(false);
  const [tutorialButton, setTutorialButton] = useState(true);
  const [loaderPDF, setLoaderPDF] = useState(false);

  useEffect(() => {
    if (allElements.length !== 0) {
      setTutorialButton(false);
    }
  }, [allElements]);

  useEffect(() => {
    isMounted.current = true;
    const currentMoodBoardFind = moodBoards.find((m) => m._id === moodBoardId);
    if (currentMoodBoardFind) {
      setCurrentMoodBoard(currentMoodBoardFind);
      setAllElements(currentMoodBoardFind.moodBoardItems || []);
    }
    return () => {
      isMounted.current = false;
    };
  }, [agentDetails, searchedAgentDetails, moodBoardId, moodBoards]);

  // Dropdown handling
  const handleDropdowns = (level) => {
    if (level === 1) {
      setShowDropdown(!showDropdown);
      setShowSecondDropdown(false);
    } else if (level === 2) {
      setShowSecondDropdown(!showSecondDropdown);
    }
  };

  // Add new element to moodBoard
  const handleSelectedElement = async (element) => {
    try {
      const response = await addItemToMoodBoard({
        userId: searchedAgentDetails._id,
        moodBoardId: moodBoardId,
        itemType: element,
      });
      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
      await fetchSharedIdeaBoards();

      setAllElements(response.data);
    } catch (error) {
      console.error("Error adding new element", error);
    }
  };
  
  const handleAddAgent = async () => {
    setShareSuccess("");
    setShareFailed("");
    setAddingAgent(true);
    try {
      const response = await addShareEditEmailToMoodBoard({
        userId: searchedAgentDetails._id,
        moodBoardId: moodBoardId,
        email: agentEmail,
      });
      if (response && response.data) {
        if (response.data.success) {
          await dispatch(AgentDataAPI({ _id: agentDetails._id }));
          await fetchSharedIdeaBoards();
          setShareSuccess(response.data.message);
        } else {
          setShareFailed(response.data.message);
        }
      }
      setAgentEmail("");
      // setAllElements(response.data);
    } catch (error) {
      console.error("Error adding new element", error);
    } finally {
      setAddingAgent(false);
    }
  };
  const handleOpenOrderElement = () => {
    setAllElements((prevElements) =>
      prevElements.filter((item) => item.itemType !== "textEdit")
    );

    setOrderElements(true);
  };
  const handleCancelOrderElement = () => {
    setAllElements((prevElements) =>
      prevElements.filter((item) => item.itemType !== "textEdit")
    );
    const currentMoodBoard = moodBoards.find((m) => m._id === moodBoardId);
    setCurrentMoodBoard(currentMoodBoard);
    if (currentMoodBoard) {
      setAllElements(currentMoodBoard.moodBoardItems || []);
    }
    dispatch(updateOpen(true));
    dispatch(updateMessage("Order Canceled Successfully"));
    setOrderElements(false);
  };
  const handleSaveOrderElement = async () => {
    try {
      await updateMoodBoard({
        userId: searchedAgentDetails._id,
        moodBoardId: moodBoardId,

        moodBoardItems: allElements,
      });
      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Order Changed Successfully"));
      setOrderElements(false);
    } catch (error) {
      console.error("Failed to update mood board", error);
    }
  };
  const handleChangeElementOrder = (index, doUp) => {
    setAllElements((prevElements) => {
      // First, make a shallow copy of the array to avoid mutating the original state directly.
      const newElements = [...prevElements];

      // Determine the new index based on the direction indicated by doUp.
      const newIndex = doUp ? index - 1 : index + 1;

      // Check if the new index is within bounds.
      if (newIndex >= 0 && newIndex < newElements.length) {
        // Swap the elements.
        [newElements[index], newElements[newIndex]] = [
          newElements[newIndex],
          newElements[index],
        ];
      }

      // Return the newly modified array.
      return newElements;
    });
  };
  const handleTextEditOpen = () => {
    const textElememt = {
      itemType: "textEdit",
    };
    setAllElements((prev) => [...prev, textElememt]);
  };

  const handleEditTextEditOpen = (element) => {
    setTextElement(element.itemValue);
    setAllElements((prevElements) =>
      prevElements.map((item) => {
        // Check if the current item is the one we want to edit
        if (item._id === element._id) {
          // Return a new object with the updated itemType
          return { ...item, itemType: "textEdit" };
        }
        // For all other items, return them unchanged
        return item;
      })
    );
  };
  // Update text element in moodBoard
  const handleTextConfirm = async (id) => {
    try {
      if (id) {
        await addMoodBoardItemValue({
          userId: searchedAgentDetails._id,
          moodBoardId: moodBoardId,
          itemId: id,
          itemValue: textElement,
        });
      } else {
        await addItemWithValueToMoodBoard({
          userId: searchedAgentDetails._id,
          moodBoardId: moodBoardId,

          itemType: "text",

          itemValue: textElement,
        });
      }
      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
      await fetchSharedIdeaBoards();

      setTextElement(""); // Clear the text editor
    } catch (error) {
      console.error("Error updating element", error);
    }
  };
  const removeValueFromMoodItem = async (itemId, prevValue, indexToRemove) => {
    if (prevValue && indexToRemove >= 0 && indexToRemove < prevValue.length) {
      // Remove the item at the specified index
      let newValue = [
        ...prevValue.slice(0, indexToRemove),
        ...prevValue.slice(indexToRemove + 1),
      ];

      try {
        setAllElements((prevElements) =>
          prevElements.map((item) => {
            // Check if the current item is the one we want to edit
            if (item._id === itemId) {
              // Return a new object with the updated itemType
              return { ...item, itemValue: newValue };
            }
            // For all other items, return them unchanged
            return item;
          })
        );
        const response = await addMoodBoardItemValue({
          userId: searchedAgentDetails._id,
          moodBoardId: moodBoardId,
          itemId: itemId,
          itemValue: newValue,
        });
        await dispatch(AgentDataAPI({ _id: agentDetails._id }));
        await fetchSharedIdeaBoards();
        dispatch(updateOpen(true));
        dispatch(updateMessage("Item Successfully Removed"));
      } catch (error) {
        console.error("Error removing item", error);
      }
    } else {
      console.error("Invalid index or empty previous value");
    }
  };
  const removeValueFromMoodItemBanner = async (itemId) => {
    try {
      const response = await addMoodBoardItemValue({
        userId: searchedAgentDetails._id,
        moodBoardId: moodBoardId,
        itemId: itemId,
        itemValue: "",
      });
      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Item Successfully Removed"));
    } catch (error) {
      console.error("Error removing item", error);
    }
  };
  // Delete element from moodBoard
  const handleDeleteElement = async (id) => {
    try {
      setAllElements((prevElements) =>
        prevElements.filter((item) => item._id !== id)
      );
      setDeleteModalActive(false);
      await deleteMoodBoardItem({
        userId: searchedAgentDetails._id,
        moodBoardId,
        itemId: id,
      });
      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
      await fetchSharedIdeaBoards();
    } catch (error) {
      console.error("Error deleting element", error);
    }
  };
  const handleCancelTextElement = async (id, index) => {
    try {
      setTextElement(""); // Clear the text editor
      if (id) {
        setAllElements((prevElements) =>
          prevElements.map((item) => {
            // Check if the current item is the one we want to edit
            if (item._id === id) {
              // Return a new object with the updated itemType
              return { ...item, itemType: "text" };
            }
            // For all other items, return them unchanged
            return item;
          })
        );
      } else {
        setAllElements((prevElements) =>
          prevElements.filter((item, idx) => idx !== index)
        );
      }
    } catch (error) {
      console.error("Error deleting element", error);
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { color: ["black", "red", "blue", "green"] },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const handlePopUpAddImage = ()=>{
    setAddImageModal(true);
  }

  const handleChangeText = (text) => {
    text = text !== "<p><br></p>" ? text : "";
    setTextElement(text);
  };
  const renderGridItem = (element, numColumns, moodBoardItemIndex) => {
    
    const images = element.itemValue || []; 
    const changeImageOrder = (imageIndex, direction) => {
      const newImages = [...images];
      if(Number.isInteger(newImages[imageIndex].index)){
        const t =  direction === "left" ? newImages[imageIndex].index - 1 : newImages[imageIndex].index + 1;
        if(t >= 0 && t < numColumns){
          const s = newImages.findIndex((element)=>element.index === t);
          newImages[imageIndex] = direction === "left" ? {...newImages[imageIndex],index : newImages[imageIndex].index - 1 } : { ...newImages[imageIndex],index : newImages[imageIndex].index + 1 };
          if(s !== -1){
            newImages[s] = direction === "left" ? {...newImages[s],index : newImages[s].index + 1 } : { ...newImages[s],index : newImages[s].index - 1 };
          }
        }
        setAllElements((prevElements) => {
          const newElements = prevElements.map((el, idx) => {
            if (idx === moodBoardItemIndex) {
              return {
                ...el, // Spread existing properties of the element
                itemValue: [...newImages], // Replace itemValue with a new array
              };
            }
            return { ...el }; // Return a shallow copy of other elements
          });
          return newElements;
        });
      }else{
        const targetIndex = direction === "left" ? imageIndex - 1 : imageIndex + 1;
  
        // Check if the target index is within bounds
        if (targetIndex >= 0 && targetIndex < images.length) {
          // Swap the images
          [newImages[imageIndex], newImages[targetIndex]] = [
            newImages[targetIndex],
            newImages[imageIndex],
          ];
          // Update the state with new images array
          setAllElements((prevElements) => {
            const newElements = prevElements.map((el, idx) => {
              if (idx === moodBoardItemIndex) {
                return {
                  ...el, // Spread existing properties of the element
                  itemValue: [...newImages], // Replace itemValue with a new array
                };
              }
              return { ...el }; // Return a shallow copy of other elements
            });
            return newElements;
          });
        }
      }
    };
    // Fill up the placeholders to match the number of columns
    const items = [];
    for(let i = 0;i < numColumns;i++){
      items.push((
        <div key={i} className="imagePlaceholder">
          <div className="addImageMyshannon" onClick={handlePopUpAddImage}>
            <p className="pIdeaBoard">Add image</p>
          </div>
        </div>
      ));
    }
    if(images && images?.length > 0){
      for(let j = 0;j < images.length;j++){
        if( Number.isInteger(images[j].index)){
          items[images[j].index] = (
            <div key={j+Math.random()*1000} className="imagescontainer">
            <div className="imagescontainer">
              {orderElements && images.length > 1 && (
                <div className="image-order-arrows">
                  <img
                    src={leftArrowCircle}
                    alt="Move left"
                    className=" orderArrow"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeImageOrder(j, "left",numColumns);
                    }}
                  />
                  <img
                    src={rightArrowCircle}
                    alt="Move right"
                    className=" orderArrow"
                    onClick={(e) => {
                      e.stopPropagation();

                      changeImageOrder(j, "right",numColumns);
                    }}
                  />
                </div>
              )}
              <img
                onClick={() => {
                  history.push(
                    `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                  );
                }}
                className=""
                src={images[j]?.artistImage}
                alt={`Grid image ${j + 1}`}
              />
              <div
                className="artistnametext-v3"
                onClick={() => {
                  history.push(
                    `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                  );
                }}
              >
                {images[j]?.artistDetails?.firstname}{" "}
                {images[j]?.artistDetails?.lastname}
              </div>
            </div>
            {haveEditAccess && (
              <div className="accessButtonsContainer">
                <img
                  src={crossSvg}
                  alt="Delete"
                  className="accessButtons"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeValueFromMoodItem(
                      element._id,
                      element.itemValue,
                      j
                    );
                  }}
                />
              </div>
            )}
          </div>
          );
        }else{
          items[j]=(
            <div key={j+Math.random()*1000} className="imagescontainer">
            <div className="imagescontainer">
              {orderElements && images.length > 1 && (
                <div className="image-order-arrows">
                  <img
                    src={leftArrowCircle}
                    alt="Move left"
                    className=" orderArrow"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeImageOrder(j, "left",numColumns);
                    }}
                  />
                  <img
                    src={rightArrowCircle}
                    alt="Move right"
                    className=" orderArrow"
                    onClick={(e) => {
                      e.stopPropagation();

                      changeImageOrder(j, "right",numColumns);
                    }}
                  />
                </div>
              )}
              <img
                onClick={() => {
                  history.push(
                    `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                  );
                }}
                className=""
                src={images[j]?.artistImage}
                alt={`Grid image ${j + 1}`}
              />
              <div
                className="artistnametext-v3"
                onClick={() => {
                  history.push(
                    `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                  );
                }}
              >
                {images[j]?.artistDetails?.firstname}{" "}
                {images[j]?.artistDetails?.lastname}
              </div>
            </div>
            {haveEditAccess && (
              <div className="accessButtonsContainer">
                <img
                  src={crossSvg}
                  alt="Delete"
                  className="accessButtons"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeValueFromMoodItem(
                      element._id,
                      element.itemValue,
                      moodBoardItemIndex
                    );
                  }}
                />
              </div>
            )}
          </div>
          )
        }
      }
    }

    return (
      <div
        key={element._id}
        className="moodBoardElementGrid"
        style={{ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }}
      >
        {orderElements && (
          <div className="orderButtonContainer">
            <div>
              <img
                src={upArrow}
                alt="up"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, true);
                }}
              />
              <img
                src={downArrow}
                alt="down"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, false);
                }}
              />
            </div>
          </div>
        )}
        {items}
        {haveEditAccess && (
          <div
            className="accessButtonsContainer"
            style={{
              top: "0px",
              right: "-30px",
            }}
          >
            <img
              src={crossSvg}
              alt="Delete"
              className="accessButtons accessButtonMain"
              onClick={() => {
                setSelectedMoodBoardItem(element);
                setDeleteModalActive(true);
              }}
            />
          </div>
        )}
      </div>
    );
  };
  const renderBannerItem = (element, moodBoardItemIndex) => {
    const image = element.itemValue || null;

    const handleImageUpload = async (event, itemId) => {
      const file = event.target.files[0];

      if (file) {
        setAllElements((prevElements) =>
          prevElements.map((item) => {
            // Check if the current item is the one we want to edit
            if (item._id === itemId) {
              // Return a new object with the updated itemType
              return { ...item, itemValue: URL.createObjectURL(file) };
            }
            // For all other items, return them unchanged
            return item;
          })
        );

        try {
          const urls = await uploadFiles([file]); // Assumed this returns an array of URLs
          const imageURL = urls[0]; // Assuming you want to append the first URL
          const response = await addMoodBoardItemValue({
            userId: searchedAgentDetails._id,
            moodBoardId: moodBoardId,
            itemId: itemId,
            itemValue: imageURL,
          });
          await dispatch(AgentDataAPI({ _id: agentDetails._id }));
          await fetchSharedIdeaBoards();
        } catch (error) {
          console.error("Error removing item", error);
        }
      }
    };

    return (
      <div
        key={element._id}
        className="moodBoardElementGrid"
        style={{ gridTemplateColumns: `repeat(1, 1fr)` }}
      >
        {haveEditAccess && (
          <div
            className="accessButtonsContainer"
            style={{
              top: "0px",
              right: "-40px",
            }}
          >
            <img
              src={crossSvg}
              alt="Delete"
              className="accessButtons accessButtonMain"
              onClick={() => {
                setSelectedMoodBoardItem(element);
                setDeleteModalActive(true);
              }}
            />
          </div>
        )}
        {orderElements && (
          <div className="orderButtonContainer">
            <div>
              <img
                src={upArrow}
                alt="up"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, true);
                }}
              />
              <img
                src={downArrow}
                alt="down"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, false);
                }}
              />
            </div>
          </div>
        )}
        {image ? (
          <div className="imageContainer">
            <img src={image} alt="Banner image" className="bannerImage" />

            {haveEditAccess && (
              <div className="accessButtonsContainer">
                <img
                  src={crossSvg}
                  alt="Delete"
                  className="accessButtons"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeValueFromMoodItemBanner(element._id);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="imagePlaceholder">
            <input
              type="file"
              onChange={(e) => handleImageUpload(e, element._id)}
              className="uploadInput"
              id="fileInput" // Added an ID for easier referencing
              accept="image/*" // Optional: Restrict file types
              style={{ display: "none" }} // Hide the input
            />
            <button
              onClick={() => document.getElementById("fileInput").click()}
              className="myBtn active sm"
            >
              Upload Image
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleCopyLink = () => {
    // The value you want to copy
    let urlToCopy = currentUrl.includes("sharedIdeaBoard")
      ? currentUrl.replace("sharedIdeaBoard", "ideaBoard")
      : currentUrl;

    // Using the Clipboard API to copy the text
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setCopySuccess("Link copied successfully!");
        // Hide the message after 2 seconds
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopySuccess("Failed to copy text.");
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };
  const handleCopyLinkShare = () => {
    // The value you want to copy
    let urlToCopy = currentUrl.includes("activeTab=ideaBoard")
      ? currentUrl.replace("ideaBoard", "sharedIdeaBoard")
      : currentUrl;

    // Using the Clipboard API to copy the text
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setCopySuccess("Link copied successfully!");
        // Hide the message after 2 seconds
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopySuccess("Failed to copy text.");
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };

  const handleRemoveAgent = async (index) => {
    setRemovingEmailIndex(index);
    const updatedEmails = [...(currentMoodBoard.sharedEditEmails || [])];
    // Remove the email at the specified index
    updatedEmails.splice(index, 1);
    try {
      const response = await updateShareEditEmailToMoodBoard({
        userId: searchedAgentDetails._id,
        moodBoardId: moodBoardId,
        emails: updatedEmails,
      });

      await dispatch(AgentDataAPI({ _id: agentDetails._id }));
      await fetchSharedIdeaBoards();

      // setAllElements(response.data);
    } catch (error) {
      console.error("Error adding new element", error);
    } finally {
      setRemovingEmailIndex(null);
    }
  };
  const handlePDFResponse = (response) => {
    try {
      if (response && response.data) {
        // Convert the response data to a blob (PDF data)
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        /* console.log(pdfBlob); // Optional: Log to check the content */

        // Create a URL from the blob and create a temporary download link
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Create a temporary download link and trigger the download
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "mood-board.pdf"; // Customize the filename if needed
        link.click(); // Trigger the download
      } else {
        console.error("Failed to generate PDF: No data in response");
      }
    } catch (error) {
      console.error("Error handling the PDF response:", error);
    }
  };
  const handleDownloadPDF = async () => {
    try {
      setLoaderPDF(true);
      // Call the backend API to generate the PDF
      const response = await apiCallToGeneratePDF({
        userId: searchedAgentDetails._id, // Assuming you already have the user ID and moodBoardId
        moodBoardId: moodBoardId,
      });

      // Handle the response (PDF blob) and trigger the download
      handlePDFResponse(response);
      setLoaderPDF(false);
    } catch (error) {
      console.error("Error during the PDF download process:", error);
    }
  };

  const handleAddElementCustomDropdown = ()=>{
    setDropDownAdd(!dropDownAdd);
    if(dropDown2Add){
      setDropDown2Add(false);
    }
  }

  return (
    <div style={{ backgroundColor: "white",maxWidth:"90vw" }}>
      {
        loaderPDF && <div style={{position:"absolute", width:"87%",height:"100%", display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"rgba(0, 0, 0, 0.5)",zIndex:"10",padding:"200px"}}>
          <div style={{backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"30px"}}>
            <img alt="loading" src={loadingUpdate} style={{ width: "40px" }}/>
            <p>Please wait. Your PDF is being generated.</p>
          </div>
        </div>
      }
      {haveEditAccess && activeTab === "ideaBoard" &&
      <div style={{ display: "grid", gridTemplateColumns: "auto 1fr",marginBottom:"40px",marginTop:"25px" }}>
        <h2
          className="hMyshannon"
          style={{ color: "rgb(250, 142, 55)", marginBottom: "0px" }}
        >
          Creating MyIdea Board
        </h2>
        <div className="separatorSubCat"></div>
      </div>
      }
      <h2 className="newsh2 h">{currentMoodBoard.title}</h2>
      {haveEditAccess && (
        <div className="ideaBoardsButtons">
          {orderElements ? (
            <>
              <button
                onClick={handleSaveOrderElement}
                className={`allArtistsButtons allArtistsButtonsActive `}
              >
                SAVE
              </button>
              <button
                onClick={handleCancelOrderElement}
                className={`allArtistsButtons`}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleOpenOrderElement}
                className={`allArtistsButtons`}
              >
                ORDER
              </button>
              <button
                className={`allArtistsButtons`}
                onClick={() => setShareModalActive(true)}
              >
                SHARE
              </button>
              <button
                className={`allArtistsButtons`}
                onClick={() => setEditAccess(true)}
              >
                EDIT ACCESS
              </button>
              <button
                className={`allArtistsButtons`}
                onClick={handleDownloadPDF}
              >
                PRINT PDF
              </button>
            </>
          )}
        </div>
      )}
      <div className="ideaboardMainDiv">
        {allElements.map((element, i) => {
          if (element.itemType === "textEdit") {
            return (
              <div key={i}>
                <ReactQuill
                  value={textElement}
                  onChange={handleChangeText}
                  modules={modules}
                  style={{ marginBottom: "50px" }}
                />
                <button
                  class="mx-1 myBtn active sm mt-3 mb-3"
                  onClick={() => handleTextConfirm(element._id)}
                >
                  {" "}
                  CONFIRM{" "}
                </button>
                <button
                  class="mx-1 myBtn sm mt-3 mb-3"
                  onClick={() => {
                    handleCancelTextElement(element._id, i);
                  }}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            );
          }
          if (element.itemType === "text") {
            return (
              <div
                key={i}
                className=""
                style={{
                  position: "relative",
                  padding: ".5px 0 0 0 ",
                  overflow:"hidden"
                }}
              >
                {orderElements && (
                  <div className="orderButtonContainer">
                    <div>
                      <img
                        src={upArrow}
                        alt="up"
                        className=" orderElementArrow"
                        onClick={() => {
                          handleChangeElementOrder(i, true);
                        }}
                      />
                      <img
                        src={downArrow}
                        alt="down"
                        className=" orderElementArrow"
                        onClick={() => {
                          handleChangeElementOrder(i, false);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  className="BioDetailDescriptionPortfolio"
                  dangerouslySetInnerHTML={{
                    __html: element.itemValue,
                  }}
                  style={{ marginBottom: "30px", marginTop: "30px"}}
                ></div>
                {haveEditAccess && (
                  <div className="accessButtonsContainer">
                    <img
                      src={editButton}
                      alt="Edit"
                      className="accessButtons"
                      onClick={() => handleEditTextEditOpen(element)}
                    />
                    <img
                      src={crossSvg}
                      alt="Unfavorite"
                      className="accessButtons"
                      onClick={() => {
                        setSelectedMoodBoardItem(element);
                        setDeleteModalActive(true);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          }
          if (element.itemType === "upload") {
            return renderBannerItem(element, i);
          }
          if (element.itemType === "grid_1") {
            return renderGridItem(element, 1, i);
          }
          if (element.itemType === "grid_2") {
            return renderGridItem(element, 2, i);
          }
          if (element.itemType === "grid_3") {
            return renderGridItem(element, 3, i);
          }
          if (element.itemType === "grid_4") {
            return renderGridItem(element, 4, i);
          }
        })}
        {
          haveEditAccess &&
          <div className="elementaryDiv" style={{position:"relative"}}>
            <div className="elementaryContainer">
              <div className="elementaryBox">
                <img className="elementaryBoxIcon elementaryLibraryIcon" src={elementLibraryIcon}/>
                <div>
                  <p className="elementaryBoxText"> ELEMENT LIBRARY</p>
                </div>
              </div>
              <div className="elemtaryElementsContainer">
                <div onClick={handleTextEditOpen} className="elemtaryElementsBox">
                  <img className="elementaryBoxIcon" src={textIcon}/>
                  <div>
                    <p className="elementaryBoxText">TEXT</p>
                  </div>
                </div>
                <div onClick={() => handleSelectedElement("grid_1")} className="elemtaryElementsBox">
                  <img className="elementaryBoxIcon" src={photoIcon}/>
                  <div>
                    <p className="elementaryBoxText">FULL WIDTH IMAGE</p> 
                  </div>
                </div>
                <div onClick={() => handleSelectedElement("grid_2")} className="elemtaryElementsBox">
                  <img className="elementaryBoxIcon columns2Icon" src={columns2Icon}/>
                  <div>
                    <p className="elementaryBoxText">2 IMAGE COLUMN GRID</p> 
                  </div>
                </div>
                <div onClick={() => handleSelectedElement("grid_3")} className="elemtaryElementsBox">
                  <img className="elementaryBoxIcon columns3Icon" src={columns3Icon}/>
                  <div>
                    <p className="elementaryBoxText">3 IMAGE COLUMN GRID</p>                     
                  </div>
                </div>
                <div onClick={() => handleSelectedElement("grid_4")} className="elemtaryElementsBox">
                  <img className="elementaryBoxIcon columns3Icon" src={columns4Icon}/>
                  <div>
                    <p className="elementaryBoxText">4 IMAGE COLUMN GRID</p>                     
                  </div>
                </div>
                <div onClick={() => handleSelectedElement("upload")} className="elemtaryElementsBox">
                  <img className="elementaryBoxIcon" src={imageUploadIcon}/>
                  <div>
                    <p className="elementaryBoxText">IMAGE UPLOAD</p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      {deleteModalActive && (
        <MyPopup
          BackClose
          onClose={() => setDeleteModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Delete {selectedMoodBoardItem.itemName} MoodBoard Item
            </h2>
            <p>Are you sure you want to delete this moodBoard Item?</p>
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn active mx-1"
                onClick={() => handleDeleteElement(selectedMoodBoardItem._id)}
              >
                DELETE
              </button>
              <button
                className="myBtn mx-1"
                onClick={() => setDeleteModalActive(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </MyPopup>
      )}
      {addImageModal && (
        <MyPopup
          BackClose
          onClose={() => setAddImageModal(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <p style={{lineHeight:"40px"}}>To add an image visit your favorite artist’s portfolio,
              <br/>select an image, and click the 
              <span style={{margin:"0px 10px"}}><img width="30px" src="/assets/images/menuWithAddOption.svg"/></span> icon at the top right of the image 
              <br/> and start building your board from there!
            </p>
            <Link to="/">
              <button className="allArtistsButtons">
                See All Artists
              </button>
            </Link>

          </div>
        </MyPopup>
      )}
      {shareModalActive && (
        <MyPopup
          BackClose
          onClose={() => setShareModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Share "{currentMoodBoard.title}" Idea Board (View Only)
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                type="text"
                disabled
                value={
                  currentUrl.includes("sharedIdeaBoard")
                    ? currentUrl.replace("sharedIdeaBoard", "ideaBoard")
                    : currentUrl
                }
                className="copyLink"
              />
              <img
                src={copyButton}
                alt="copy"
                className="simpleAccessButtons"
                onClick={() => handleCopyLink()}
              />
            </div>
            {copySuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {copySuccess}
              </div>
            )}
            <br />
            OR
            <br />
            <h2 style={{ color: "#D16A27" }}>
              Share "{currentMoodBoard.title}" to collaborate,
            </h2>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="agentEmail">Email Address:</label>
              <input
                type="email"
                id="agentEmail"
                placeholder="Enter email"
                value={agentEmail}
                onChange={(e) => setAgentEmail(e.target.value)}
                className="emailInput"
              /><br/>
              <button
                className={`allArtistsButtons allArtistsButtonsActive agentButton`}
                onClick={handleAddAgent}
                disabled={addingAgent}
              >
                {addingAgent ? "Adding..." : "Add Collaborator"}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <input
                type="text"
                disabled
                value={
                  currentUrl.includes("activeTab=ideaBoard")
                    ? currentUrl.replace("ideaBoard", "sharedIdeaBoard")
                    : currentUrl
                }
                className="copyLink"
              />
              <img
                src={copyButton}
                alt="copy"
                className="simpleAccessButtons"
                onClick={() => handleCopyLinkShare()}
              />
            </div>
            {copySuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {copySuccess}
              </div>
            )}
            {shareSuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {shareSuccess}
              </div>
            )}
            {shareFailed && (
              <div style={{ color: "red", margin: "10px" }}>{shareFailed}</div>
            )}
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn mx-1"
                onClick={() => setShareModalActive(false)}
              >
                Close
              </button>
            </div>
          </div>
        </MyPopup>
      )}
      {editAccess && (
        <MyPopup
          BackClose
          onClose={() => setEditAccess(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Added Agents in {currentMoodBoard.title}
            </h2>

            <div className="alreadyAddedEmails">
              {currentMoodBoard &&
              currentMoodBoard.sharedEditEmails &&
              currentMoodBoard.sharedEditEmails.length > 0 ? (
                <ul className="agentEmailList">
                  {currentMoodBoard.sharedEditEmails.map((email, index) => (
                    <li key={index} className="agentEmailItem">
                      {email}
                      <button
                        className="removeAgentButton"
                        onClick={() => handleRemoveAgent(index)}
                        disabled={removingEmailIndex === index}
                      >
                        {removingEmailIndex === index
                          ? "Removing..."
                          : "Remove"}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="noClientsMessage">No Client added</p>
              )}
            </div>

            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn mx-1"
                onClick={() => setEditAccess(false)}
              >
                Close
              </button>
            </div>
          </div>
        </MyPopup>
      )}
    </div>
  );
};

export default AgentMoodBoardEdit;
